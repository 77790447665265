import React, { useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import './Category.css';

const Category = ({ categories, onSelectCategory, language, onClearCategory }) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const handleSelectCategory = (category) => {
    setSelectedCategoryId(category.id);
    onSelectCategory(category);
  };

  const handleClearCategory = () => {
    setSelectedCategoryId(null);
    onClearCategory();
  };

  return (
    <div className='category-container'>
      {/* Header section with title and additional text */}
      <Grid
  container
  justifyContent="space-between"
  alignItems="center"
  sx={{
    backgroundColor: '#721d20',
    padding: '15px 20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginTop: '-68px',
    position: 'absolute',
    color:"white",
    width: '96%',
    margin: '-70px auto',
  }}
>
  {/* Render based on the language */}
  {language === 'EN' ? (
    <>
      <Grid item xs={3} sx={{ textAlign: 'left' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#ffffff' }}>
          Categories
        </Typography>
      </Grid>
      <Grid item xs={3} sx={{ textAlign: 'right', color: '#666', fontStyle: 'italic' }}>
        <Typography variant="body1" sx={{ color: '#ccc', fontSize: '0.9rem', lineHeight: 1.5 }}>
          Please select a category to filter the courses
        </Typography>
      </Grid>
    </>
  ) : (
    <>
      <Grid item xs={3} sx={{ textAlign: 'left', color: '#ccc', fontStyle: 'italic' }}>
        <Typography variant="body1" sx={{ color: '#888', fontSize: '0.9rem', lineHeight: 1.5 }}>
          اختر القسم لفلترة الدورات التدريبية
        </Typography>
      </Grid>
      <Grid item xs={3} sx={{ textAlign: 'right' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#fffff' }}>
          الاقسام
        </Typography>
      </Grid>
    </>
  )}
</Grid>


      {categories.length === 0 ? (
        <Typography variant="h6" align="center">
          {language === 'EN' ? 'No categories available.' : 'لا توجد فئات متاحة.'}
        </Typography>
      ) : (
        <Grid container spacing={2} justifyContent="center">
          {categories.map((category) => (
            <Grid
              item
              key={category.id}
              xs={6}
              sm={4}
              md={2}
              onClick={() => handleSelectCategory(category)}
              className={`category-item ${selectedCategoryId === category.id ? 'selected' : ''}`}
            >
              <div
                style={{
                  backgroundImage: `url(${category.icon})`,
                }}
                className="category-circle"
              />
              <Typography variant="body1" className="category-title">
                {language === 'EN' ? category.nameEN : category.nameAR}
              </Typography>
            </Grid>
          ))}

          <Grid item xs={12} style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button
              variant="outlined"
              onClick={handleClearCategory}
              className="clear-filter-button"
            >
              {language === 'EN' ? 'Clear Filter' : 'مسح الفلتر'}
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Category;
