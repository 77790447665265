import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, doc, deleteDoc } from 'firebase/firestore'; // Firestore methods
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'; // Firebase Storage methods
import { db } from '../../config/firebase'; // Your Firebase configuration
import Snackbar from '@mui/material/Snackbar'; // For success/error messages
import MuiAlert from '@mui/material/Alert'; // For the alert message
import { Oval } from 'react-loader-spinner'; // Loading spinner
import './AddPlace.css'; // Similar styles as AddPlace

const AddGallery = () => {
    const [image, setImage] = useState(null); // State for the selected image
    const [loading, setLoading] = useState(false); // Loading state
    const [alertOpen, setAlertOpen] = useState(false); // Snackbar state
    const [alertMessage, setAlertMessage] = useState(''); // Snackbar message
    const [alertSeverity, setAlertSeverity] = useState('success'); // Snackbar severity
    const [galleryImages, setGalleryImages] = useState([]); // Store existing gallery images
    const [editMode, setEditMode] = useState(false); // Track if we are editing

    // Fetch existing gallery images on component mount
    useEffect(() => {
        const fetchGalleryImages = async () => {
            const querySnapshot = await getDocs(collection(db, 'gallery')); // Fetch images from the 'gallery' collection
            const images = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setGalleryImages(images); // Store existing images in state
        };

        fetchGalleryImages();
    }, []);

    // Handle image file selection
    const handleImageChange = (e) => {
        setImage(e.target.files[0]); // Set the selected image file
    };

    // Handle image upload or edit
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!image) {
            setAlertMessage('Please select an image to upload.');
            setAlertSeverity('error');
            setAlertOpen(true);
            return;
        }

        // Check if the gallery already has 6 images
        if (galleryImages.length >= 6) {
            setAlertMessage('You can only upload a maximum of 6 images.');
            setAlertSeverity('error');
            setAlertOpen(true);
            return;
        }

        setLoading(true); // Show loading spinner

        try {
            const storage = getStorage();


            // Upload the image to Firebase Storage
            const storageRef = ref(storage, `gallery-images/${image.name}`); // Create a reference in 'gallery-images'
            await uploadBytes(storageRef, image); // Upload the image file
            const imageUrl = await getDownloadURL(storageRef); // Get the image URL from Firebase Storage

            // Add the image URL to Firestore 'gallery' collection
            await addDoc(collection(db, 'gallery'), {
                imageUrl: imageUrl,
            });

            // Update the state with the new image
            setGalleryImages((prev) => [...prev, { imageUrl }]);

            setAlertMessage('Image uploaded successfully!');


            setAlertSeverity('success');
            setAlertOpen(true);
            setImage(null); // Clear the selected file
            setEditMode(false); // Reset edit mode after submission
        } catch (error) {
            console.error('Error uploading image: ', error);
            setAlertMessage('Error uploading image. Please try again.');
            setAlertSeverity('error');
            setAlertOpen(true);
        }

        setLoading(false); // Hide loading spinner
    };

    // Handle Snackbar close
    const handleCloseSnackbar = () => {
        setAlertOpen(false);
    };



    return (
        <div className="add-place-container"> {/* Using the same style as AddPlace */}
            <h2>{editMode ? 'Edit Gallery Image' : 'Add Gallery Image'}</h2>
            <form onSubmit={handleSubmit} className="add-place-form"> {/* Same form style */}
                <div className="form-group">
                    <label>Select Image:</label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        required
                        disabled={!editMode && galleryImages.length >= 6} // Disable if 6 images already uploaded
                    />
                </div>
                <button type="submit" className="submit-button" disabled={loading || (!editMode && galleryImages.length >= 6)}>
                    {loading ? (
                        <Oval height={24} width={24} color="#fff" />
                    ) : editMode ? 'Update Image' : 'Upload Image'}
                </button>
            </form>

            {/* Snackbar for success/error messages */}
            <Snackbar
                open={alertOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MuiAlert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMessage}
                </MuiAlert>
            </Snackbar>

            {/* Show existing gallery images */}
            <div className="gallery-preview">
                <h3>Gallery Preview ({galleryImages.length}/6)</h3>

            </div>
        </div>
    );
};

export default AddGallery;
