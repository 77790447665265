// src/admin/components/ViewAllBanners.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase'; // Import the Firestore instance
import Snackbar from '@mui/material/Snackbar'; // Import Snackbar for success message
import MuiAlert from '@mui/material/Alert'; // Import MuiAlert for alert message
import ConfirmationDialog from './ConfirmationDialog'; // Import confirmation dialog
import './AddScheduledCourse.css'; // Reuse CSS file

const ViewAllBanners = () => {
  const [banners, setBanners] = useState([]); // State for banners
  const [alertOpen, setAlertOpen] = useState(false); // State for Snackbar
  const [alertMessage, setAlertMessage] = useState(''); // Alert message text
  const [alertSeverity, setAlertSeverity] = useState('success'); // Alert severity (success/error)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // State for confirmation dialog
  const [courseToRemove, setCourseToRemove] = useState(null); // State to hold course to remove

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const bannersSnapshot = await getDocs(collection(db, 'courses'));
        const bannersData = bannersSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(course => course.banner); // Filter for courses that are banners
        setBanners(bannersData); // Set banners state
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();
  }, []);

  const handleRemove = async () => {
    if (!courseToRemove) return;

    try {
      const courseDoc = doc(db, 'courses', courseToRemove);
      await updateDoc(courseDoc, {
        banner: false // Reset banner flag
      });

      setAlertMessage('Course removed from banners successfully!');
      setAlertSeverity('success');
      setAlertOpen(true);
      setCourseToRemove(null); // Clear course to remove
      setConfirmDialogOpen(false); // Close confirmation dialog

      // Refresh banners
      const bannersSnapshot = await getDocs(collection(db, 'courses'));
      const bannersData = bannersSnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter(course => course.banner);
      setBanners(bannersData); // Update banners state
    } catch (error) {
      console.error('Error removing course from banners:', error);
      setAlertMessage('Failed to remove course from banners');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleOpenConfirmDialog = (courseId) => {
    setCourseToRemove(courseId); // Set course to remove
    setConfirmDialogOpen(true); // Open confirmation dialog
  };

  const handleCloseSnackbar = () => {
    setAlertOpen(false); // Close the Snackbar
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false); // Close confirmation dialog
    setCourseToRemove(null); // Clear course to remove
  };

  return (
    <div className="view-all-banners-container">
      <h2>All Banners</h2>
      {banners.length === 0 ? (
        <p>No banners available.</p>
      ) : (
        <ul>
          {banners.map((banner) => (
            <li key={banner.id}>
              {banner.courseNameEN} / {banner.courseNameAR}
              <button onClick={() => handleOpenConfirmDialog(banner.id)}>Remove from Banners</button>
            </li>
          ))}
        </ul>
      )}

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity={alertSeverity}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>

      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleRemove}
        message="Are you sure you want to remove this course from banners?"
      />
    </div>
  );
};

export default ViewAllBanners;
