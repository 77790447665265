// src/admin/components/AddBanner.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase'; // Import the Firestore instance
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar'; // Import Snackbar for success message
import MuiAlert from '@mui/material/Alert'; // Import MuiAlert for alert message
import { Oval } from 'react-loader-spinner'; // For loading spinner
import './AddScheduledCourse.css'; // Reuse CSS file

const AddBanner = () => {
  const [courses, setCourses] = useState([]); // State for courses dropdown
  const [selectedCourse, setSelectedCourse] = useState(''); // State for selected course
  const [loading, setLoading] = useState(false); // State for loading spinner
  const [alertOpen, setAlertOpen] = useState(false); // State for Snackbar
  const [alertMessage, setAlertMessage] = useState(''); // Alert message text
  const [alertSeverity, setAlertSeverity] = useState('success'); // Alert severity (success/error)

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch all courses from Firestore
    const fetchCourses = async () => {
      try {
        const coursesSnapshot = await getDocs(collection(db, 'courses'));
        const coursesData = coursesSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(course => !course.banner); // Filter out courses that are already banners
        setCourses(coursesData); // Populate courses dropdown
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCourse) {
      setAlertMessage('Please select a course');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    setLoading(true); // Show loading spinner

    try {
      // Update the selected course in Firestore to mark it as a banner
      const courseDoc = doc(db, 'courses', selectedCourse);
      await updateDoc(courseDoc, {
        banner: true // Mark course as banner
      });

      setAlertMessage('Course added to banners successfully!');
      setAlertSeverity('success');
      setAlertOpen(true);

      setTimeout(() => navigate('/admin/view-banners'), 1500); // Navigate back to view banners after success
    } catch (error) {
      console.error('Error adding course to banners:', error);
      setAlertMessage('Failed to add course to banners');
      setAlertSeverity('error');
      setAlertOpen(true);
    }

    setLoading(false); // Hide loading spinner
  };

  const handleCloseSnackbar = () => {
    setAlertOpen(false); // Close the Snackbar
  };

  return (
    <div className="add-scheduled-course-container">
      <h2>Add a Banner</h2>
      <form onSubmit={handleSubmit} className="add-scheduled-course-form">
        <div className="form-group">
          <label>Select a Course:</label>
          <select
            value={selectedCourse}
            onChange={(e) => setSelectedCourse(e.target.value)}
            required
          >
            <option value="">Select a course</option>
            {courses.map((course) => (
              <option key={course.id} value={course.id}>
                {course.courseNameEN} / {course.courseNameAR}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <button type="submit" disabled={loading}>
            {loading ? (
              <Oval
                height={20}
                width={20}
                color="#fff"
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              'Add Banner'
            )}
          </button>
        </div>
      </form>

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity={alertSeverity}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default AddBanner;
