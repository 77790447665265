import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'; // Import TextField for search input
import './ViewPlaces.css';

const ViewAllScheduledCourse = () => {
  const [scheduledCourses, setScheduledCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]); // State for filtered courses
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [courseToRemove, setCourseToRemove] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term

  const navigate = useNavigate();

  useEffect(() => {
    const fetchScheduledCourses = async () => {
      setLoading(true);
      try {
        const coursesSnapshot = await getDocs(collection(db, 'courses'));
        const scheduledCoursesData = coursesSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(course => course.ScheduledCourse === true);

        const today = new Date().toISOString().split("T")[0];
        for (const course of scheduledCoursesData) {
          if (course.endDate < today) {
            await updateDoc(doc(db, 'courses', course.id), {
              startDate: '',
              endDate: '',
              ScheduledCourse: false
            });
            setAlertMessage(`Course "${course.courseNameEN}" has expired and has been unscheduled.`);
            setAlertSeverity('warning');
            setAlertOpen(true);
          }
        }

        const validScheduledCourses = scheduledCoursesData.filter(course => course.endDate >= today);
        setScheduledCourses(validScheduledCourses);
        setFilteredCourses(validScheduledCourses); // Initialize filteredCourses with all scheduled courses

      } catch (error) {
        console.error('Error fetching scheduled courses:', error);
      }
      setLoading(false);
    };

    fetchScheduledCourses();
  }, []);

  useEffect(() => {
    // Filter courses based on the search term
    const filtered = scheduledCourses.filter((course) =>
      course.courseNameEN.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.courseNameAR.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCourses(filtered);
  }, [searchTerm, scheduledCourses]);

  const handleRemoveScheduled = async () => {
    if (!courseToRemove) return;

    setLoading(true);
    try {
      const courseDoc = doc(db, 'courses', courseToRemove.id);
      await updateDoc(courseDoc, {
        startDate: '',
        endDate: '',
        ScheduledCourse: false
      });

      setAlertMessage('Course successfully unscheduled!');
      setAlertSeverity('success');
      setAlertOpen(true);
      setScheduledCourses(scheduledCourses.filter(course => course.id !== courseToRemove.id));
      setFilteredCourses(filteredCourses.filter(course => course.id !== courseToRemove.id));

    } catch (error) {
      console.error('Error unscheduling course:', error);
      setAlertMessage('Failed to unschedule course.');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
    setLoading(false);
    setConfirmationOpen(false);
  };

  const handleOpenConfirmation = (course) => {
    setCourseToRemove(course);
    setConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  const handleCloseSnackbar = () => {
    setAlertOpen(false);
  };

  return (
    <div className="view-places-container">
      <h2>Scheduled Courses</h2>

      {/* Search bar for filtering courses */}
      <TextField
        label="Search Scheduled Courses"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search by course name (EN/AR)"
      />

      {loading ? (
        <p>Loading...</p>
      ) : filteredCourses.length === 0 ? (
        <p>No scheduled courses available.</p>
      ) : (
        <table className="places-table">
          <thead>
            <tr>
              <th>Course Name (EN)</th>
              <th>Course Name (AR)</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Places (EN/AR)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredCourses.map((course) => (
              <tr key={course.id}>
                <td>{course.courseNameEN}</td>
                <td>{course.courseNameAR}</td>
                <td>{course.startDate}</td>
                <td>{course.endDate}</td>
                <td>
                  {course.places && course.places.length > 0 ? (
                    course.places.map((place, index) => (
                      <div key={index}>
                        {place.nameEN} - {place.nameAR}
                      </div>
                    ))
                  ) : (
                    'No places selected'
                  )}
                </td>
                <td>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/admin/edit-scheduled-course/${course.id}`)}
                    style={{ marginRight: '10px' }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleOpenConfirmation(course)}
                  >
                    Remove from Scheduled
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Snackbar for Success/Error Messages */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity={alertSeverity}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmationOpen}
        onClose={handleCloseConfirmation}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">Remove Course from Scheduled</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            Are you sure you want to remove this course from the scheduled list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRemoveScheduled} color="secondary" autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewAllScheduledCourse;
