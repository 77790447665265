// src/components/Login.js
import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
} from '@mui/material';
import { motion } from 'framer-motion'; // For animations
import { auth } from '../../config/firebase'; // Import the Firebase auth instance
import { signInWithEmailAndPassword } from 'firebase/auth'; // Import Firebase auth method
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing
import './AdminLogin.css'; // Import the CSS file

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Call useNavigate inside the component

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful');
      navigate('/admin'); // Redirect to the dashboard after successful login
    } catch (error) {
      setError("Incorrect Username or Password");
      console.error('Login failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xl" className="login-container">
      {/* <div className="right-side"></div> */}
      <Paper
        elevation={3}
        component={motion.div}
        initial={{ opacity: 0, y: -20 }} // Animate in from above
        animate={{ opacity: 1, y: 0 }} // Fade in effect
        transition={{ duration: 0.5 }}
        className="login-paper"
      >
        <Typography variant="h4" align="center" gutterBottom className="login-title">
          Sajaya Admin
        </Typography>
        {error && (
          <Typography
            color="error"
            variant="body2"
            align="center"
            className="login-error"
          >
            {error}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="login-input"
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="login-input"
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            disabled={loading}
            className="login-button"
          >
            {loading ? 'Loading...' : 'Log In'}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default AdminLogin;
