import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Snackbar,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import emailjs from '@emailjs/browser';
import noImage from '../../../assets/no_img.png';
import './Modal.css';

const Modal = ({ isOpen, onClose, course, language }) => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [submissionMethod, setSubmissionMethod] = useState('whatsapp');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  if (!isOpen || !course) return null;

  const handleAlertOpen = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleWhatsAppSubmit = () => {
    const whatsappNumber = '+962791804757';
    const message = `
      Course Name: ${language === 'EN' ? course.courseNameEN : course.courseNameAR}
      Description: ${language === 'EN' ? course.descriptionEN : course.descriptionAR}
      Duration: ${course.duration} ${language === "EN" ? "hours" : "ساعات"}
      Name: ${name}
      Phone Number: ${phoneNumber}
      ${email ? `Email: ${email}` : ''}
    `;

    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message.trim())}`;
    window.open(whatsappUrl, '_blank');
    handleAlertOpen('Request sent via WhatsApp!', 'success');
    resetForm();
  };

  const handleEmailSubmit = () => {
    const templateParams = {
      course_name: language === 'EN' ? course.courseNameEN : course.courseNameAR,
      course_description: language === 'EN' ? course.descriptionEN : course.descriptionAR,
      course_duration: `${course.duration} ${language === "EN" ? "hours" : "ساعات"}`,
      your_name: name,
      phone_number: phoneNumber,
      email_address: email || 'Not provided',
    };

    emailjs
      .send(
        'service_ue1k5u3',
        'template_vw8vsuk',
        templateParams,
        '-NyTlsymFZ68BaArD'
      )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        handleAlertOpen('Your email has been sent successfully!', 'success');
        resetForm();
      })
      .catch((error) => {
        console.error('FAILED...', error);
        handleAlertOpen('There was an error sending your email. Please try again.', 'error');
      });
  };

  const handleSubmit = () => {
    if (submissionMethod === 'whatsapp') {
      handleWhatsAppSubmit();
    } else if (submissionMethod === 'email') {
      handleEmailSubmit();
    }
  };

  const resetForm = () => {
    setName('');
    setPhoneNumber('');
    setEmail('');
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen} onClose={resetForm} maxWidth="md" fullWidth>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ textAlign: language === 'AR' ? 'right' : 'left' }}>
            {language === 'EN' ? course.courseNameEN : course.courseNameAR}
          </Typography>
          <IconButton onClick={resetForm}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
            <img
              src={course.imageUrl ? course.imageUrl : noImage}
              alt={course.courseNameEN}
              style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }}
            />
          </Box>
          <Typography variant="body1" sx={{ mb: 2, textAlign: language === 'AR' ? 'right' : 'left' }}>
            {language === 'EN' ? course.descriptionEN : course.descriptionAR}
          </Typography>

          <Typography variant="subtitle1" sx={{ mb: 1, textAlign: language === 'AR' ? 'right' : 'left' }}>
            <strong>{language === 'EN' ? 'Duration:' : 'المدة:'}</strong> {course.duration} {language === 'EN' ? 'hours' : 'ساعات'}
          </Typography>

          <Box sx={{ textAlign: language === 'AR' ? 'right' : 'left', width: '100%' }}>
            <FormLabel component="legend" sx={{ marginTop: 2, textAlign: language === 'AR' ? 'right' : 'left' }}>
              {language === 'EN' ? 'Fill your info to send your request, and contact with you' : 'قدم بياناتك لتقديم الطلب والتواصل معك'}
            </FormLabel>
            <TextField
              label={language === 'EN' ? 'Your Name' : 'اسمك'}
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              required
              sx={{ mb: 2, textAlign: language === 'AR' ? 'right' : 'left' }}
            />
            <TextField
              label={language === 'EN' ? 'Your Phone Number' : 'رقم هاتفك'}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              fullWidth
              required
              sx={{ mb: 2, textAlign: language === 'AR' ? 'right' : 'left' }}
            />
            <TextField
              label={language === 'EN' ? 'Your Email (Optional)' : 'بريدك الإلكتروني (اختياري)'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              sx={{ mb: 2, textAlign: language === 'AR' ? 'right' : 'left' }}
            />
          </Box>
          <Box sx={{ textAlign: language === 'AR' ? 'right' : 'left', width: '100%' }}>
            <FormLabel component="legend" sx={{ marginTop: 2, textAlign: language === 'AR' ? 'right' : 'left' }}>
              {language === 'EN' ? 'How would you like to send your request?' : 'كيف ترغب في إرسال طلبك؟'}
            </FormLabel>
            <RadioGroup
              row
              value={submissionMethod}
              onChange={(e) => setSubmissionMethod(e.target.value)}
              sx={{ justifyContent: language === 'AR' ? 'flex-end' : 'flex-start', marginTop: 1 }}
            >
              <FormControlLabel value="whatsapp" control={<Radio />} label={language === 'EN' ? 'WhatsApp' : 'واتساب'} />
              <FormControlLabel value="email" control={<Radio />} label={language === 'EN' ? 'Email' : 'البريد الالكتروني'} />
            </RadioGroup>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!name || !phoneNumber}>
            {language === 'EN' ? 'Request The Course' : 'اطلب الدورة التدريبية'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar Alert for Success/Error Messages */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Centered on the screen
        sx={{ zIndex: 2000 }} // Ensure it appears above other elements
      >
        <Alert onClose={handleAlertClose} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Modal;
