import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { getStorage, ref, deleteObject } from 'firebase/storage'; // For Storage deletion
import { db } from '../../config/firebase';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'; // Material UI TextField for search input
import { Oval } from 'react-loader-spinner'; // For the loading spinner
import './ViewPlaces.css';

const ViewCourses = () => {
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]); // State for filtered courses
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const navigate = useNavigate();

  // Fetch courses from Firestore
  useEffect(() => {
    const fetchCourses = async () => {
      const courseSnapshot = await getDocs(collection(db, 'courses'));
      const fetchedCourses = courseSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setCourses(fetchedCourses);
      setFilteredCourses(fetchedCourses); // Initialize filteredCourses with all courses
    };

    fetchCourses();
  }, []);

  // Filter courses based on the search term
  useEffect(() => {
    const filtered = courses.filter((course) =>
      course.courseNameEN.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.courseNameAR.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCourses(filtered);
  }, [searchTerm, courses]);

  // Open the delete confirmation dialog
  const handleDeleteClick = (course) => {
    setCourseToDelete(course);
    setOpenConfirm(true);
  };

  // Handle the course deletion
  const handleConfirmDelete = async () => {
    setOpenConfirm(false);
    setLoading(true);
    const storage = getStorage();

    try {
      await deleteDoc(doc(db, 'courses', courseToDelete.id));

      if (courseToDelete.imageUrl) {
        const storageRef = ref(storage, courseToDelete.imageUrl);
        await deleteObject(storageRef);
      }

      setCourses(courses.filter(course => course.id !== courseToDelete.id));
      setFilteredCourses(filteredCourses.filter(course => course.id !== courseToDelete.id));

      setAlertMessage(`Successfully deleted "${courseToDelete.courseNameEN}"`);
      setAlertSeverity('success');
      setAlertOpen(true);
    } catch (error) {
      console.error('Error deleting course:', error);
      setAlertMessage('Failed to delete course');
      setAlertSeverity('error');
      setAlertOpen(true);
    }

    setLoading(false);
  };

  // Close the snackbar alert
  const handleCloseSnackbar = () => {
    setAlertOpen(false);
  };

  // Navigate to Edit course
  const handleEdit = (course) => {
    navigate('/admin/add-course', { state: { course, isEdit: true } });
  };

  return (
    <div className="view-places-container">
      <h2>View Courses</h2>

      {/* Search bar for filtering courses */}
      <TextField
        label="Search Courses"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search by course name (EN/AR)"
      />

      <table className="places-table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Course Name (EN)</th>
            <th>Course Name (AR)</th>
            <th>Category</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredCourses.map((course) => (
            <tr key={course.id}>
              <td>
                <img src={course.imageUrl} alt={course.courseNameEN} style={{ width: '50px', height: '50px' }} />
              </td>
              <td>{course.courseNameEN}</td>
              <td>{course.courseNameAR}</td>
              <td>{course.category.nameEN} / {course.category.nameAR}</td>
              <td>
                <button onClick={() => handleEdit(course)}>Edit</button>
                <button onClick={() => handleDeleteClick(course)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Confirm Delete Dialog */}
      <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
        <DialogTitle>Delete Course</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete "{courseToDelete?.courseNameEN}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirm(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Loading Spinner */}
      {loading && (
        <div className="loading-spinner">
          <Oval
            height={80}
            width={80}
            color="#4fa94d"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      {/* Snackbar for Alert Messages */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity={alertSeverity}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ViewCourses;
