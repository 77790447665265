// src/admin/components/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css'; // Import the CSS file

const Sidebar = () => {
  return (
    <div className="sidebar">
      <h2 style={{color:"#3fbedc"}}>Admin Panel</h2>
      <ul>
        <h3 style={{color:"#3fbedc",textAlign:"start"}}>Courses</h3>
        <li>
          <Link to="/admin/add-course">Add New Course</Link>
        </li>
        <li>
          <Link to="/admin/view-courses">View All Courses</Link>
        </li>
        <h3 style={{color:"#3fbedc",textAlign:"start"}}>Places</h3>
        <li>
          <Link to="/admin/add-place">Add New Place</Link> {/* Add this link */}
        </li>
        <li>
          <Link to="/admin/view-place">View All Places</Link> {/* Add this link */}
        </li>
       <h3 style={{color:"#3fbedc",textAlign:"start"}}>Categories</h3>
        <li>
          <Link to="/admin/add-category">Add New Category</Link> {/* Add this link */}
        </li>
        <li>
          <Link to="/admin/view-category">View All category</Link> {/* Add this link */}
        </li>
        <h3 style={{color:"#3fbedc",textAlign:"start"}}>Gallery</h3>
        <li>
          <Link to="/admin/add-gallery">Add New Gallery</Link> {/* Add this link */}
        </li>
        <li>
          <Link to="/admin/view-gallery">View All Gallery</Link> {/* Add this link */}
        </li>
        <h3 style={{color:"#3fbedc",textAlign:"start"}}>Scheduled Course</h3>
        <li>
          <Link to="/admin/add-scheduled">Add New Scheduled Course</Link> {/* Add this link */}
        </li>
        <li>
          <Link to="/admin/view-scheduled">View All Scheduled Course</Link> {/* Add this link */}
        </li>
        <h3 style={{color:"#3fbedc",textAlign:"start"}}>Banner</h3>
        <li>
          <Link to="/admin/add-banner">Add New Banner</Link> {/* Add this link */}
        </li>
        <li>
          <Link to="/admin/view-banners">View All Banners</Link> {/* Add this link */}
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
