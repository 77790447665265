// src/user/components/Gallery/Gallery.js
import React,{useState} from 'react';
import './Gallery.css'; // For your styling and animation
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";

const Gallery = ({ galleryImages,language }) => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  if (!galleryImages || galleryImages.length === 0) {
    return (
      <></>
    // <div className="no-images">No images available</div>;
    )
  }

  const openLightbox = (index) => {
    // alert(currentImage)
    setCurrentImage(index);
    console.log(currentImage)

    setLightboxIsOpen(true);
  };

  return (
    <div className="gallery-container">
  <div className="gallery-header">
    <h2>{language === "EN" ? "Sajaya's Academy Gallery" : "معرض أكاديمية سجايا"}</h2>
    <p>
      {language === "EN" 
        ? "Academy Center Gallery: Welcome to our academy center! Explore our dynamic learning spaces, interactive classrooms, and modern facilities that foster growth and creativity. From hands-on workshops to collaborative projects, our students are engaged in diverse activities that inspire innovation. Experience our vibrant community through these moments captured in the heart of education and development."
        : "مرحبًا بكم في مركز الأكاديمية! استكشف مساحات التعلم الديناميكية والفصول الدراسية التفاعلية والمرافق الحديثة التي تعزز النمو والإبداع. من ورش العمل العملية إلى المشاريع التعاونية، يشارك طلابنا في أنشطة متنوعة تلهم الإبداع. استمتع بمجتمعنا النابض بالحياة من خلال هذه اللحظات التي تم التقاطها في قلب التعليم والتنمية."
      }
    </p>
  </div>

  <Lightbox
        // open={lightboxIsOpen}
        close={() => setLightboxIsOpen(false)}
        // slides={galleryImages.map(img => ({ src: img.imageUrl }))}
        // currentIndex={currentImage}
        // render={galleryImages[currentImage].imageUrl}
        // animation={Lightbox}
        renderHeader={({ close }) => (
          <div className="lightbox-header">
            <button className="close-button" onClick={close}>✕</button>
          </div>
        )}
      />

  {galleryImages.map((image, index) => (
  <div 
    key={index} 
    className="gallery-image-wrapper" 
    onClick={() => openLightbox(index)}
    style={{
      backgroundImage: `url(${image.imageUrl})`,
      backgroundSize: 'content',
      // backgroundSize:''
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }}
  >
    {/* Optional overlay content or fallback text */}
    {image.altText ? (
      <div className="gallery-overlay">
        <p>{image.altText || `Gallery Image ${index + 1}`}</p>
      </div>
    ) : null}
  </div>
))}

  
</div>

  );
};

export default Gallery;
