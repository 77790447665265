import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar'; // Material UI Snackbar
import MuiAlert from '@mui/material/Alert'; // Material UI Alert
import Dialog from '@mui/material/Dialog'; // Material UI Dialog
import DialogActions from '@mui/material/DialogActions'; // Dialog Actions (buttons)
import DialogContent from '@mui/material/DialogContent'; // Dialog content (message)
import DialogContentText from '@mui/material/DialogContentText'; // Dialog content text
import DialogTitle from '@mui/material/DialogTitle'; // Dialog title
import Button from '@mui/material/Button'; // Material UI Button
import TextField from '@mui/material/TextField'; // TextField for search input
import { Oval } from 'react-loader-spinner'; // Loading spinner
import './ViewPlaces.css';

const ViewPlaces = () => {
  const [places, setPlaces] = useState([]);
  const [filteredPlaces, setFilteredPlaces] = useState([]); // State for filtered places
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [placeToDelete, setPlaceToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const navigate = useNavigate();

  // Fetch places from Firestore
  useEffect(() => {
    const fetchPlaces = async () => {
      const placeSnapshot = await getDocs(collection(db, 'places'));
      const fetchedPlaces = placeSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setPlaces(fetchedPlaces);
      setFilteredPlaces(fetchedPlaces); // Initialize filteredPlaces with all places
    };
    fetchPlaces();
  }, []);

  // Filter places based on the search term
  useEffect(() => {
    const filtered = places.filter((place) =>
      place.nameEN.toLowerCase().includes(searchTerm.toLowerCase()) ||
      place.nameAR.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPlaces(filtered);
  }, [searchTerm, places]);

  const handleDeleteClick = (place) => {
    setPlaceToDelete(place);
    setOpenConfirm(true);
  };

  const handleConfirmDelete = async () => {
    setOpenConfirm(false);
    setLoading(true);
    try {
      await deleteDoc(doc(db, 'places', placeToDelete.id));
      setAlertMessage(`Successfully deleted "${placeToDelete.nameEN}"`);
      setAlertSeverity('success');
      setAlertOpen(true);
      setPlaces(places.filter((place) => place.id !== placeToDelete.id));
      setFilteredPlaces(filteredPlaces.filter((place) => place.id !== placeToDelete.id));
    } catch (error) {
      console.error('Error deleting place:', error);
      setAlertMessage('Failed to delete place');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
    setLoading(false);
  };

  const handleCloseSnackbar = () => {
    setAlertOpen(false);
  };

  return (
    <div className="view-places-container">
      <h2>View Places</h2>

      {/* Search bar for filtering places */}
      <TextField
        label="Search Places"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search by place name (EN/AR)"
      />

      <table className="places-table">
        <thead>
          <tr>
            <th>Name English</th>
            <th>Name Arabic</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredPlaces.map((place) => (
            <tr key={place.id}>
              <td>{place.nameEN}</td>
              <td>{place.nameAR}</td>
              <td>
                <button onClick={() => navigate('/admin/add-place', { state: { place } })}>
                  Edit
                </button>
                <button onClick={() => handleDeleteClick(place)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Confirm Delete Dialog */}
      <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
        <DialogTitle>Delete Place</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete "{placeToDelete?.nameEN}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirm(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Loading Spinner */}
      {loading && (
        <div className="loading-spinner">
          <Oval
            height={80}
            width={80}
            color="#4fa94d"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      {/* Snackbar for Alert Messages */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity={alertSeverity}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ViewPlaces;
