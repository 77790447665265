import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore'; // Firestore methods
import { getStorage, ref, deleteObject } from 'firebase/storage'; // Firebase Storage methods
import { db } from '../../config/firebase'; // Your Firebase configuration
import './Gallery.css'; // Import the new Gallery CSS file
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'; // Material-UI components
import { useNavigate } from 'react-router-dom'; // For navigation

const ViewGallery = () => {
  const [galleryImages, setGalleryImages] = useState([]); // Store existing gallery images
  const [openConfirm, setOpenConfirm] = useState(false); // State for dialog visibility
  const [imageToDelete, setImageToDelete] = useState(null); // Store image to delete
  const navigate = useNavigate(); // Initialize navigation

  // Fetch existing gallery images on component mount
  useEffect(() => {
    const fetchGalleryImages = async () => {
      const querySnapshot = await getDocs(collection(db, 'gallery'));
      const images = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setGalleryImages(images); // Store existing images in state
    };

    fetchGalleryImages();
  }, []);

  // Handle delete image confirmation
  const handleDelete = (image) => {
    setImageToDelete(image); // Set the image to delete
    setOpenConfirm(true); // Open the confirmation dialog
  };

  // Confirm delete action
  const handleConfirmDelete = async () => {
    if (imageToDelete) {
      const { id, imageUrl } = imageToDelete; // Extract id and URL from the image

      const storage = getStorage();
      const imageRef = ref(storage, imageUrl); // Create a reference to the image in Firebase Storage

      try {
        // Delete image from Firebase Storage
        await deleteObject(imageRef);

        // Delete image from Firestore
        await deleteDoc(doc(db, 'gallery', id));

        // Update the local state to remove the deleted image
        setGalleryImages((prev) => prev.filter((img) => img.id !== id));
      } catch (error) {
        console.error('Error deleting image: ', error);
      }

      setOpenConfirm(false); // Close the dialog after delete
    }
  };



  return (
    <div className="view-gallery-container">
      <h2>Gallery Images</h2>
      {galleryImages.length === 0 ? ( // Check if gallery is empty
        <div className="no-images">
          <p>No images available. Click below to add an image.</p>
          <Button variant="contained" color="primary" onClick={() => navigate('/admin/add-gallery')}>
            Add Image Here
          </Button>
        </div>
      ) : (
        <div className="gallery-grid">
          {galleryImages.map((img) => (
            <div key={img.id} className="gallery-image-card">
              <img src={img.imageUrl} alt={`Gallery`} />
              <div className="gallery-image-actions">
                {/* <button className="edit-button" onClick={() => handleEdit(img)}>Edit</button> */}
                <button className="delete-button" onClick={() => handleDelete(img)}>Delete</button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Confirmation Dialog for Deletion */}
      <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
        <DialogTitle>Delete Image</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this image? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirm(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewGallery;
