// src/config/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

// const firebaseConfig = {
//     apiKey: "AIzaSyBPy51Ljij55rHve0li0edjP8YAaShH2A4",
//     authDomain: "sajaya-b0f84.firebaseapp.com",
//     projectId: "sajaya-b0f84",
//     storageBucket: "sajaya-b0f84.appspot.com",
//     messagingSenderId: "413307990134",
//     appId: "1:413307990134:web:ffe4c70f7b7eeeae752f57",
//     measurementId: "G-KGS3VQWRTB"
//   };
const firebaseConfig = {
  apiKey: "AIzaSyDLsfJJBMMkL6hOJ1JZabptDg5kSCiOUeM",
  authDomain: "sajaya-adbfa.firebaseapp.com",
  projectId: "sajaya-adbfa",
  storageBucket: "sajaya-adbfa.appspot.com",
  messagingSenderId: "691078706349",
  appId: "1:691078706349:web:affe19a18af4967231c224",
  measurementId: "G-5WBSC7658Q"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const auth = getAuth(app);
export { db,storage,analytics,auth };
