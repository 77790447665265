import React, { useState, useEffect } from 'react';
import { collection,query, where, getDocs, addDoc, updateDoc, doc } from 'firebase/firestore';
import { db, storage } from '../../config/firebase'; // Firebase configuration
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Oval } from 'react-loader-spinner';
import { useLocation, useNavigate } from 'react-router-dom'; // To handle navigation and passed data
import './AddPlace.css'; // Use AddPlace styles for AddCategory

const AddCategory = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Get the category data passed from ViewCategory.js for editing
  const categoryData = location.state?.category || null;
  const isEdit = location.state?.isEdit || false;

  // State variables for form fields
  const [nameEN, setCategoryName] = useState(categoryData?.nameEN || ''); // English name
  const [nameAR, setCategoryArabicName] = useState(categoryData?.nameAR || ''); // Arabic name
  const [icon, setIcon] = useState(null);
  const [iconUrl, setIconUrl] = useState(categoryData?.icon || ''); // To hold current icon URL
  const [loading, setLoading] = useState(false); // Loading spinner state
  const [alertOpen, setAlertOpen] = useState(false); // Snackbar open/close state
  const [alertMessage, setAlertMessage] = useState(''); // Alert message
  const [alertSeverity, setAlertSeverity] = useState('success'); // Alert type

  // Handle icon file change
  const handleIconChange = (e) => {
    setIcon(e.target.files[0]);
  };

  // Form submission handler
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   try {
  //     let finalIconUrl = iconUrl; // If there's no new icon, use the existing one

  //     if (icon) {
  //       const storageRef = ref(storage, `category-icons/${icon.name}`);
  //       await uploadBytes(storageRef, icon);
  //       finalIconUrl = await getDownloadURL(storageRef); // Get the URL of the uploaded icon
  //     }

  //     if (isEdit) {
  //       // Update the existing category
  //       const categoryDoc = doc(db, 'categories', categoryData.id);
  //       await updateDoc(categoryDoc, {
  //         nameEN: nameEN,
  //         nameAR: nameAR, // Add Arabic name field
  //         icon: finalIconUrl,
  //       });
  //       setAlertMessage('Category updated successfully!');    
  //       setAlertSeverity('success'); // Set alert to success
  //       setAlertOpen(true);
  //       setTimeout(() => navigate('/admin/view-category'), 1500); 
  //     } else {
  //       // Add a new category
  //       await addDoc(collection(db, 'categories'), {
  //         nameEN: nameEN,
  //         nameAR: nameAR, // Add Arabic name field
  //         icon: finalIconUrl,
  //       });
  //       setCategoryName('');
  //       setCategoryArabicName(''); // Clear Arabic name field
  //       setIcon(null);
  //       setAlertMessage('Category added successfully!');
  //     }

  //     setAlertSeverity('success');
  //     setAlertOpen(true);
  //   } catch (error) {
  //     console.error('Error adding/updating category: ', error);
  //     setAlertMessage('Error adding/updating category. Please try again.');
  //     setAlertSeverity('error');
  //     setAlertOpen(true);
  //   }

  //   setLoading(false);
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      let finalIconUrl = iconUrl; // If there's no new icon, use the existing one
  
      if (icon) {
        const storageRef = ref(storage, `category-icons/${icon.name}`);
        await uploadBytes(storageRef, icon);
        finalIconUrl = await getDownloadURL(storageRef); // Get the URL of the uploaded icon
      }
  
      if (isEdit) {
        // Update the existing category
        const categoryDoc = doc(db, 'categories', categoryData.id);
        await updateDoc(categoryDoc, {
          nameEN: nameEN,
          nameAR: nameAR, // Add Arabic name field
          icon: finalIconUrl,
        });
  
        // Find and update all courses that have this category
        const coursesQuery = query(
          collection(db, 'courses'),
          where('category.nameEN', '==', categoryData.nameEN), // Query courses by English category name
          where('category.nameAR', '==', categoryData.nameAR)  // Query by Arabic category name as well
        );
        const courseDocs = await getDocs(coursesQuery);
        
        const batchPromises = courseDocs.docs.map(async (courseDoc) => {
          const courseRef = doc(db, 'courses', courseDoc.id);
          const courseData = courseDoc.data();
  
          // Update the category details in the course document
          await updateDoc(courseRef, {
            'category.nameEN': nameEN,
            'category.nameAR': nameAR,
            'category.icon': finalIconUrl,
          });
        });
  
        await Promise.all(batchPromises);
  
        setAlertMessage('Category and associated courses updated successfully!');
        setAlertSeverity('success'); // Set alert to success
        setAlertOpen(true);
        setTimeout(() => navigate('/admin/view-category'), 1500); 
      } else {
        // Add a new category
        await addDoc(collection(db, 'categories'), {
          nameEN: nameEN,
          nameAR: nameAR, // Add Arabic name field
          icon: finalIconUrl,
        });
        setCategoryName('');
        setCategoryArabicName(''); // Clear Arabic name field
        setIcon(null);
        setAlertMessage('Category added successfully!');
      }
  
      setAlertSeverity('success');
      setAlertOpen(true);
    } catch (error) {
      console.error('Error adding/updating category: ', error);
      setAlertMessage('Error adding/updating category. Please try again.');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  
    setLoading(false);
  };
  // Close Snackbar function
  const handleCloseSnackbar = () => {
    setAlertOpen(false);
  };

  return (
    <div className="add-place-container"> {/* Reuse the AddPlace styles */}
      <h2>{isEdit ? 'Edit Category' : 'Add New Category'}</h2> {/* Title changes dynamically */}
      <form onSubmit={handleSubmit} className="add-place-form"> {/* Use AddPlace form styles */}
        {/* English Category Name Input */}
        <div className="form-group">
          <label>Category Name (English):</label>
          <input
            type="text"
            value={nameEN}
            onChange={(e) => setCategoryName(e.target.value)}
            required
          />
        </div>

        {/* Arabic Category Name Input */}
        <div className="form-group">
          <label>اسم التصنيف (Arabic):</label>
          <input
            type="text"
            value={nameAR}
            onChange={(e) => setCategoryArabicName(e.target.value)}
            required
          />
        </div>

        {/* Icon Input */}
        <div className="form-group">
          <label>Category Icon:</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleIconChange}
          />
          {/* Display existing icon if editing */}
          {iconUrl && !icon && (
            <img src={iconUrl} alt="Category Icon" style={{ width: '100px', height: 'auto' }} />
          )}
        </div>

        {/* Submit Button */}
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? (
            <Oval height={24} width={24} color="#fff" />
          ) : (isEdit ? 'Update Category' : 'Add Category')}
        </button>
      </form>

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default AddCategory;
