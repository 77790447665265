import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Snackbar,
  Alert
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../../assets/sajaya_logo.png'; 
import { useMediaQuery } from '@mui/material'; 
import emailjs from '@emailjs/browser';

const Header = ({ language, setLanguage, setSearchTerm }) => {
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false); // Snackbar state
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success'); // Can be 'success' or 'error'
  const [courseName, setCourseName] = useState('');
  const [yourName, setYourName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [submissionMethod, setSubmissionMethod] = useState('whatsapp');
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleLanguageSwitch = (lang) => setLanguage(lang);
  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setCourseName('');
    setYourName('');
    setPhoneNumber('');
    setEmail('');
    setSubmissionMethod('whatsapp');
  };

  const closeAlert = () => setAlertOpen(false);

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
    setTimeout(closeAlert, 1500); // Auto-close after 1.5 seconds
  };

  const handleWhatsAppSubmit = () => {
    const message = `
      Course Name: ${courseName}
      Your Name: ${yourName}
      Phone Number: ${phoneNumber}
      Email: ${email ? email : 'Not provided'}
    `;
    const whatsappNumber = '+962791804757';
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
    showAlert(language === 'EN' ? 'Request sent via WhatsApp!' : 'تم إرسال الطلب عبر واتساب', 'success');
    handleClose();
  };

  const handleEmailSubmit = () => {
    const templateParams = { course_name: courseName, your_name: yourName, phone_number: phoneNumber, email_address: email };

    emailjs.send('service_ue1k5u3', 'template_dro4o0m', templateParams, '-NyTlsymFZ68BaArD')
      .then(() => {
        showAlert(language === 'EN' ? 'Email sent successfully!' : 'تم إرسال البريد الإلكتروني بنجاح', 'success');
        handleClose();
      })
      .catch(() => showAlert(language === 'EN' ? 'Error sending email.' : 'خطأ في إرسال البريد الإلكتروني', 'error'));
  };

  const handleSubmit = () => {
    submissionMethod === 'whatsapp' ? handleWhatsAppSubmit() : handleEmailSubmit();
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#f5f5f5', boxShadow: 'none', borderBottom: '1px solid #ddd' }}>
      <Toolbar sx={{ flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'space-between', gap: isMobile ? 2 : 0 }}>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton edge="start" color="inherit" aria-label="logo" sx={{ p: 0 }}>
            <img src={logo} alt="Sajaya Logo" style={{ height: '40px' }} />
          </IconButton>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', width: isMobile ? '100%' : '50%' }}>
          <SearchIcon sx={{ color: '#b79269', mr: 1 }} />
          <TextField
            variant="outlined"
            placeholder= {language==="EN"?"Search...":"... إبحث"}
            fullWidth
            size="small"
            onChange={handleSearchChange}
            style={{textAlign:language==="EN"?'left !important':'right !important'}}
            InputProps={{
              sx: {
                borderRadius: '4px',
                borderColor: '#b79269',
                '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#721d20' },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#721d20' },
              },
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'space-between' : 'center', width: isMobile ? '100%' : 'auto' }}>
          <Button
            variant="contained"
            style={{ backgroundColor: language === 'EN' ? '#721d20' : '#b79269', color: 'white' }}
            onClick={() => handleLanguageSwitch('EN')}
            sx={{ marginRight: isMobile ? 0 : 1, textTransform: 'none', width: isMobile ? '48%' : 'auto' }}
          >
            EN
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: language === 'AR' ? '#721d20' : '#b79269', color: 'white' }}
            onClick={() => handleLanguageSwitch('AR')}
            sx={{ textTransform: 'none', width: isMobile ? '48%' : 'auto' }}
          >
            عربي
          </Button>
          <Button
            variant="contained"
            onClick={handleClickOpen}
            sx={{
              backgroundColor: '#721d20',
              color: 'white',
              marginLeft: 1,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#5e191b',
                boxShadow: 'none'
              },
            }}
          >
            {language === "EN" ? "Request a Course" : "اطلب دورتك التدريبية"}
          </Button>
        </Box>

        {/* Dialog for Request a Course */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{language === "EN" ? "Request a Course" : "اطلب دورة تدريبية"}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={language === "EN" ? "Enter Course Name" : "ادخل اسم الدورة التدريبية"}
              type="text"
              fullWidth
              variant="outlined"
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
              required
            />
            <TextField
              margin="dense"
              label={language === "EN" ? "Enter Your Name" : "ادخل اسمك"}
              type="text"
              fullWidth
              variant="outlined"
              value={yourName}
              onChange={(e) => setYourName(e.target.value)}
              required
            />
            <TextField
              margin="dense"
              label={language === "EN" ? "Enter Your Phone Number" : "ادخل رقم هاتفك مع مفتاح الدولة"}
              type="text"
              fullWidth
              variant="outlined"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
            <TextField
              margin="dense"
              label={language === "EN" ? "Your Email (optional)" : "ادخل ايميلك (اختياري)"}
              type="email"
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormLabel component="legend" sx={{ marginTop: 2 }}>{language === "EN" ? "How would you like to send your request?" : "كيف ترغب في إرسال طلبك؟"}</FormLabel>
            <RadioGroup
              row
              value={submissionMethod}
              onChange={(e) => setSubmissionMethod(e.target.value)}
            >
              <FormControlLabel value="whatsapp" control={<Radio />} label="WhatsApp" />
              <FormControlLabel value="email" control={<Radio />} label="Email" />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={{ color: '#721d20' }}>
              {language === "EN" ? "Cancel" : "الغاء"}
            </Button>
            <Button onClick={handleSubmit} sx={{ backgroundColor: '#721d20', color: 'white' }}>
              {language === "EN" ? "Send your request" : "ارسل طلبك"}
            </Button>
          </DialogActions>
        </Dialog>

      </Toolbar>

      {/* Snackbar for Alerts */}
      <Snackbar open={alertOpen} autoHideDuration={1500} onClose={closeAlert} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </AppBar>
  );
};

export default Header;
