// src/admin/components/AddCourse.js
import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../config/firebase'; // Import storage
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import storage methods
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate and useLocation
import Snackbar from '@mui/material/Snackbar'; // Import Snackbar for success message
import MuiAlert from '@mui/material/Alert'; // Import MuiAlert for alert message
import { Oval } from 'react-loader-spinner'; // For loading spinner
import './AddCourse.css'; // Import the CSS file

const AddCourse = () => {
  const location = useLocation();
  const navigate = useNavigate(); // For navigation
  const courseData = location.state?.course; // Get course data if editing
  const isEdit = location.state?.isEdit; // Check if it is edit mode
  const [courseNameEN, setCourseNameEN] = useState(courseData?.courseNameEN || '');
  const [courseNameAR, setCourseNameAR] = useState(courseData?.courseNameAR || '');
  const [descriptionEN, setDescriptionEN] = useState(courseData?.descriptionEN || '');
  const [descriptionAR, setDescriptionAR] = useState(courseData?.descriptionAR || '');
  const [duration, setDuration] = useState(courseData?.duration || '');
  // const [place, setPlace] = useState(courseData?.place || { en: '', ar: '' }); // Store place as object
  // const [price, setPrice] = useState(courseData?.price || '');
  // const [featuresEN, setFeaturesEN] = useState(courseData?.featuresEN.join(', ') || '');
  // const [featuresAR, setFeaturesAR] = useState(courseData?.featuresAR.join(', ') || '');
  // const [topicsEN, setTopicsEN] = useState(courseData?.topicsEN.join(', ') || '');
  // const [topicsAR, setTopicsAR] = useState(courseData?.topicsAR.join(', ') || '');
  const [image, setImage] = useState(null); // State for the image
  const [categories, setCategories] = useState([]); // State for categories
  const [category, setCategory] = useState(courseData?.category || { en: '', ar: '' }); // Store category as object
  const [imageUrl, setImageUrl] = useState(courseData?.imageUrl || ''); // Existing image URL
  const [durations, setDurations] = useState([]);
  // const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading spinner
  const [alertOpen, setAlertOpen] = useState(false); // State for Snackbar
  const [alertMessage, setAlertMessage] = useState(''); // Alert message text
  const [alertSeverity, setAlertSeverity] = useState('success'); // Alert severity (success/error)

  useEffect(() => {
    // Fetch durations, places, and categories from Firebase
    const fetchData = async () => {
      const durationSnapshot = await getDocs(collection(db, 'durations'));
      // const placeSnapshot = await getDocs(collection(db, 'places'));
      const categorySnapshot = await getDocs(collection(db, 'categories'));

      setDurations(durationSnapshot.docs.map(doc => doc.data().name)); // Assuming 'durations' only have names

      // const placesData = placeSnapshot.docs.map(doc => ({
      //   nameEN: doc.data().nameEN,
      //   nameAR: doc.data().nameAR
      // }));

    
      // setPlaces(placesData);

      const categoriesData = categorySnapshot.docs.map(doc => ({
        nameEN: doc.data().nameEN,
        nameAR: doc.data().nameAR
      }));
      setCategories(categoriesData);

      // If in edit mode, set the existing place and category from courseData
      if (isEdit && courseData) {
        // alert(JSON.stringify(courseData))
        // alert(JSON.stringify(placesData))
        // alert(JSON.stringify(categoriesData))
        // const selectedPlace = placesData.find(p => p.nameEN === courseData.place.nameEN);
        const selectedCategory = categoriesData.find(c => c.nameEN === courseData.category.nameEN);
        // if (selectedPlace) {
        //   setPlace({ nameEN: selectedPlace.nameEN, nameAR: selectedPlace.nameAR });
        // }
        if (selectedCategory) {
          setCategory({ nameEN: selectedCategory.nameEN, nameAR: selectedCategory.nameAR });
        }
      }
    };

    fetchData();
  }, [courseData, isEdit]);

  const clearFormFields = () => {
    setCourseNameEN('');
    setCourseNameAR('');
    setDescriptionEN('');
    setDescriptionAR('');
    setDuration('');
    // setPlace({ nameEN: '', nameAR: '' });
    // setPrice('');
    // setFeaturesEN('');
    // setFeaturesAR('');
    // setTopicsEN('');
    // setTopicsAR('');
    setImage(null);
    setCategory({ nameEN: '', nameAR: '' });
    setImageUrl('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loading spinner

    let finalImageUrl = imageUrl; // Use existing URL unless a new image is uploaded
    if (image) {
      const imageRef = ref(storage, `courses/${image.name}`); // Create a reference for the image
      await uploadBytes(imageRef, image); // Upload the image
      finalImageUrl = await getDownloadURL(imageRef); // Get the new image URL
    }

    try {
      if (isEdit) {
        const courseDoc = doc(db, 'courses', courseData.id); // Get the course document reference
        await updateDoc(courseDoc, {
          courseNameEN,
          courseNameAR,
          descriptionEN,
          descriptionAR,
          duration,
          // place, // Store place as object {en: value, ar: value}
          // price,
          // featuresEN: featuresEN.split(',').map(item => item.trim()),
          // featuresAR: featuresAR.split(',').map(item => item.trim()),
          // topicsEN: topicsEN.split(',').map(item => item.trim()),
          // topicsAR: topicsAR.split(',').map(item => item.trim()),
          imageUrl: finalImageUrl, // Store the new or existing image URL
          category, // Store category as object {en: value, ar: value}
        });
        setAlertMessage('Course updated successfully!');
        setAlertSeverity('success'); // Set alert to success
        setAlertOpen(true);
        setTimeout(() => navigate('/admin/view-courses'), 1500);
      } else {
        await addDoc(collection(db, 'courses'), {
          courseNameEN,
          courseNameAR,
          descriptionEN,
          descriptionAR,
          duration,
          // place, // Store place as object {en: value, ar: value}
          // price,
          // featuresEN: featuresEN.split(',').map(item => item.trim()),
          // featuresAR: featuresAR.split(',').map(item => item.trim()),
          // topicsEN: topicsEN.split(',').map(item => item.trim()),
          // topicsAR: topicsAR.split(',').map(item => item.trim()),
          imageUrl: finalImageUrl, // Store the image URL
          category, // Store category as object {en: value, ar: value}
        });
        setAlertMessage('Course added successfully!');
        setAlertSeverity('success'); // Set alert to success
        setAlertOpen(true); // Show success Snackbar

        clearFormFields(); // Clear form fields after adding
      }
    } catch (error) {
      console.error('Error updating course:', error);
      setAlertMessage('Failed to update course');
      setAlertSeverity('error'); // Set alert to error
      setAlertOpen(true); // Show error Snackbar
    }

    setLoading(false); // Hide loading spinner
  };

  const handleCloseSnackbar = () => {
    setAlertOpen(false); // Close the Snackbar
  };

  return (
    <div className="add-course-container">
      <h2>{isEdit ? 'Edit Course' : 'Add New Course'}</h2> {/* Change title based on edit mode */}
      <form onSubmit={handleSubmit} className="add-course-form">
        <div className="form-group">
          <label>Course Name (English):</label>
          <input
            type="text"
            value={courseNameEN}
            onChange={(e) => setCourseNameEN(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Course Name (Arabic):</label>
          <input
            type="text"
            value={courseNameAR}
            onChange={(e) => setCourseNameAR(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Description (English):</label>
          <textarea
            value={descriptionEN}
            onChange={(e) => setDescriptionEN(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Description (Arabic):</label>
          <textarea
            value={descriptionAR}
            onChange={(e) => setDescriptionAR(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Duration:</label>
          <input
            type="number"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            required
          />
        </div>
        {/* <div className="form-group">
          <label>Place:</label>
          <select
            value={place.nameEN}
            onChange={(e) => {
              const selectedPlace = places.find(p => p.nameEN === e.target.value);
              setPlace(selectedPlace);
            }}
            required
          >
            <option value="">Select a place</option>
            {places.map((p, index) => (
              <option key={index} value={p.nameEN}>
                {p.nameEN} / {p.nameAR}
              </option>
            ))}
          </select>
        </div> */}
        <div className="form-group">
          <label>Category:</label>
          <select
            value={category.nameEN}
            onChange={(e) => {
              const selectedCategory = categories.find(c => c.nameEN === e.target.value);
              setCategory(selectedCategory);
            }}
            required
          >
            <option value="">Select a category</option>
            {categories.map((c, index) => (
              <option key={index} value={c.nameEN}>
                {c.nameEN} / {c.nameAR}
              </option>
            ))}
          </select>
        </div>
        {/* <div className="form-group">
          <label>Price:</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />
        </div> */}
        {/* <div className="form-group">
          <label>Features (English):</label>
          <textarea
            value={featuresEN}
            onChange={(e) => setFeaturesEN(e.target.value)}
            required
          />
        </div> */}
        {/* <div className="form-group">
          <label>Features (Arabic):</label>
          <textarea
            value={featuresAR}
            onChange={(e) => setFeaturesAR(e.target.value)}
            required
          />
        </div> */}
        {/* <div className="form-group">
          <label>Topics (English):</label>
          <textarea
            value={topicsEN}
            onChange={(e) => setTopicsEN(e.target.value)}
            required
          />
        </div> */}
        {/* <div className="form-group">
          <label>Topics (Arabic):</label>
          <textarea
            value={topicsAR}
            onChange={(e) => setTopicsAR(e.target.value)}
            required
          />
        </div> */}
        <div className="form-group">
          <label>Image:</label>
          <input
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
            accept="image/*"
            required={false}
          />
        </div>
        {imageUrl && (
          <div className="form-group">
            <img src={imageUrl} alt="Course" className="course-image-preview" />
          </div>
        )}
        <div className="form-group">
          <button type="submit" disabled={loading}>
            {loading ? (
              <Oval
                height={20}
                width={20}
                color="#fff"
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              isEdit ? 'Update Course' : 'Add Course'
            )}
          </button>
        </div>
      </form>

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity={alertSeverity}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default AddCourse;
