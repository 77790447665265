import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { IconButton } from '@mui/material';

const WhatsAppFloatingIcon = ({ phoneNumber }) => {
  const handleWhatsAppClick = () => {
    const message = encodeURIComponent('Hello, I need assistance.'); // Default message
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
    window.open(url, '_blank');
  };

  return (
    <IconButton
      onClick={handleWhatsAppClick}
      sx={{
        position: 'fixed',
        bottom: '20px',
        right: '60px',
        maxWidth: '40px',
        backgroundColor: '#25D366',
        color: 'white',
        animation: 'jump 1.5s ease-in-out infinite',  // Animation for bouncing effect
        '&:hover': {
          backgroundColor: '#128C7E',
        },
        '@keyframes jump': {
          '0%, 100%': {
            transform: 'translateY(0)',  // At start and end, no movement
          },
          '50%': {
            transform: 'translateY(-15px)',  // Moves up by 15px at the middle
          },
        },
      }}
    >
      <WhatsAppIcon />
    </IconButton>
  );
};

export default WhatsAppFloatingIcon;
