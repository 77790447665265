import React from 'react';
import './Footer.css';
import { FaFacebook, FaInstagram, FaTwitter,FaLinkedin } from 'react-icons/fa';

const Footer = ({ language }) => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {language === 'EN' ? (
          <>
            <div className="footer-left">
              <h3 style={{ textAlign: "left", color: "white" }}>Sajaya Academy</h3>
              <div className="footer-description ltr">
                <p style={{ color: "white" }}>
                  Sajaya Academy for Training and Management Development was established in 2019 as a specialized institution offering high-quality training programs in the fields of management and professional development. The academy stands out for its accreditation by the Cambridge Academy for Administrative and Technical Training, enhancing its credibility and providing trainees with internationally recognized certificates.
                </p>
              </div>
            </div>
            <div className="contact-card">
              <h3>Contact Us</h3>
              <div className="contact-info">
                <p><strong>Tel:</strong> <a href="tel:+96265517131">+962 6 5517131</a></p>
                <p><strong>Mob:</strong> <a href="tel:+962791804757">+962 791804757</a></p>
                <p>
                  <strong>Email:</strong>
                  <a href="mailto:info@sajaya.academy"> info@sajaya.academy </a>&nbsp;<strong>OR</strong>&nbsp;
                  <a href="mailto:stad.academyjo@gmail.com"> stad.academyjo@gmail.com </a>
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="contact-card" >
              <h3 style={{color:"white",textAlign:"right"}}>اتصل بنا</h3>
              <div className="contact-info" >
                <p style={{color:"white",textAlign:"right",direction:"rtl"}}><strong>هاتف:</strong> <a href="tel:+96265517131" style={{color:"black",textAlign:"right",direction:"ltr"}}>٠٠٩٦٢/٦٥٥١٧١٣١</a></p>
                <p style={{color:"white",textAlign:"right"}}><strong>جوال:</strong> <a href="tel:+962791804757"style={{color:"black",textAlign:"right",direction:"ltr"}}> ٠٠٩٦٢/٧٩١٨٠٤٧٥٧</a></p>
                <p style={{color:"white",textAlign:"right"}}>
                  <strong style={{color:"white"}}>:البريد الإلكتروني</strong>
                  <span><a href="mailto:info@sajaya.academy" style={{color:"black"}}> info@sajaya.academy </a>
                  <a href="mailto:stad.academyjo@gmail.com" style={{color:"black"}}> stad.academyjo@gmail.com </a></span>
                </p>
              </div>
            </div>
            <div className="footer-left" style={{textAlign:"right"}}>
              <h3 style={{ textAlign: "right", color: "black" }}>اكاديمية سجايا</h3>
              <div className="footer-description rtl" style={{textAlign:"right"}}>
                <span style={{ textAlign: "right",width:"30px" }}>
                  تأسست أكاديمية سجايا للتدريب والتطوير الإداري في عام 2019، وهي مؤسسة متخصصة في تقديم برامج
                  تدريبية عالية الجودة في مجالات الإدارة والتطوير المهني. تتميز الأكاديمية بأنها معتمدة من أكاديمية
                  كامبردج للتدريب الإداري والتقني، مما يعزز من مصداقيتها ويوفر للمتدربين شهادات معترف بها دولياً
                </span>
              </div>
            </div>
          </>
        )}
      </div>

      <p style={{ color: "white" }}>{language === 'EN' ? 'Follow us on:' : 'تابعنا على:'}</p>
      <div className="social-links">
        <a href="https://www.facebook.com/share/1XoB6oJCdN/" aria-label="Follow us on Facebook"><FaFacebook /></a>
        <a href="https://www.instagram.com/academystad/profilecard/?igsh=MWVyYTV4YWExNTZoZA==" aria-label="Follow us on Instagram"><FaInstagram /></a>
        <a href="https://www.linkedin.com/in/sajaya-stad-48a296330/" aria-label="Follow us on LinkedIn"><FaLinkedin /></a>
      </div>
      <p style={{ color: "white" }}>&copy; Sajaya 2024</p>
    </footer>
  );
};

export default Footer;
