import React, { useState, useEffect, useRef } from 'react';
import Header from './components/Header/Header';
import Banner from './components/Banner/Banner';
import Category from './components/Category/Category';
import Courses from './components/Courses/Courses';
import Gallery from './components/Gallery/Gallery';
import UpcomingEvents from './components/UpcomingEvents/UpcomingEvents';
import Footer from './components/Footer/Footer';
import WhatsAppFloatingIcon from '../WhatsAppIcon';
import { collection, getDocs, query, where, updateDoc, doc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { Grid } from '@mui/material';
import './HomePage.css';

const HomePage = () => {
  const [language, setLanguage] = useState('AR');
  const [banners, setBanners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [upcomingCourses, setUpcomingCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // Create a ref for the Courses section
  const coursesRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch banners
        const bannerQuery = query(collection(db, 'courses'), where('banner', '==', true));
        const bannerSnapshot = await getDocs(bannerQuery);
        const fetchedBanners = bannerSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        // Fetch categories
        const categorySnapshot = await getDocs(collection(db, 'categories'));
        const fetchedCategories = categorySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        // Fetch all courses
        const courseSnapshot = await getDocs(collection(db, 'courses'));
        const fetchedCourses = courseSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        // Fetch gallery images
        const gallerySnapshot = await getDocs(collection(db, 'gallery'));
        const fetchedGalleryImages = gallerySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        // Filter upcoming courses
        const today = new Date().toISOString().split('T')[0];
        for (const course of fetchedCourses) {
          if (course.endDate < today) {
            await updateDoc(doc(db, 'courses', course.id), {
              startDate: '',
              endDate: '',
              ScheduledCourse: false,
            });
          }
        }
        const upcomingCourses = fetchedCourses.filter((course) => course.endDate >= today);

        // Set fetched data to state
        setBanners(fetchedBanners);
        setCategories(fetchedCategories);
        setCourses(fetchedCourses);
        setFilteredCourses(fetchedCourses);
        setGalleryImages(fetchedGalleryImages);
        setUpcomingCourses(upcomingCourses);
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
      }
    };

    fetchData();
  }, []);

  // Handle category selection filtering and scroll to Courses section
  const handleCategorySelect = (category) => {
    const filtered = courses.filter((course) =>
      course.category.nameEN === category.nameEN || course.category.nameAR === category.nameAR
    );
    setFilteredCourses(filtered);
    coursesRef.current?.scrollIntoView({ behavior: 'smooth' }); // Scroll to Courses section
  };

  // Clear category filter and show all courses
  const handleClearCategory = () => {
    setFilteredCourses(courses);
  };

  // Filter courses by search term
  useEffect(() => {
    const filtered = courses.filter(
      (course) =>
        course.courseNameEN.toLowerCase().includes(searchTerm.toLowerCase()) ||
        course.courseNameAR.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCourses(filtered);
  }, [searchTerm, courses]);

  return (
    <div className="container-home-page">
      <Header language={language} setLanguage={setLanguage} setSearchTerm={setSearchTerm} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Banner banners={banners} language={language} />
        </Grid>
        <Grid item xs={12}>
          <Category
            categories={categories}
            onSelectCategory={handleCategorySelect}
            onClearCategory={handleClearCategory}
            language={language}
          />
        </Grid>
        <Grid item xs={12} ref={coursesRef}> {/* Add the ref here */}
          <Courses courses={filteredCourses} language={language} />
        </Grid>
        <Grid item xs={12}>
          <UpcomingEvents upcomingCourses={upcomingCourses} language={language} />
        </Grid>
        <Grid item xs={12}>
          <Gallery galleryImages={galleryImages} language={language} />
        </Grid>
      </Grid>
      <Footer language={language} />
      <WhatsAppFloatingIcon phoneNumber="+962791804757" />
    </div>
  );
};

export default HomePage;
