import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography, Button } from '@mui/material'; // Import Material UI components
import Modal from '../Modal/Modal'; // Import Modal component
import './Courses.css';
import noImage from '../../../assets/no_img.png'
// Helper function to truncate text to the first 6 words
const truncateText = (text, wordLimit = 12) => {
  const wordsArray = text.split(' ');
  if (wordsArray.length > wordLimit) {
    return wordsArray.slice(0, wordLimit).join(' ') + '...'; // Add ellipsis after 6 words
  }
  return text;
};

const Courses = ({ courses, language }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [visibleCourses, setVisibleCourses] = useState(6); // Manage the number of visible courses
  // Open the modal with selected course details
  const openModal = (course) => {
    setSelectedCourse(course);
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setTimeout(() => {
      setIsModalOpen(false);
    setSelectedCourse(null);
    }, 1500); 
   
  };

  // Show more courses on button click
  const loadMoreCourses = () => {
    setVisibleCourses((prevVisibleCourses) => prevVisibleCourses + 6);
  };

  return (
    <div className="courses-container">
      {courses.length === 0 ? (
        <Typography variant="h6" align="center">
          No courses available.
        </Typography>
      ) : (
        <>
          {/* Render only visible courses */}
          <Grid container spacing={2} justifyContent="center">
            {courses.slice(0, visibleCourses).map((course) => (
              <Grid item key={course.id} xs={12} sm={6} md={4}>
                <Card
                  className="course-card"
                  onClick={() => openModal(course)} // Open modal on click
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%', // Ensure cards are of equal height
                    // backgroundImage: course.imageUrl?`url(${course.imageUrl})`:{noImage},
                    backgroundImage: `url(${course.imageUrl ? course.imageUrl : noImage})`,
                    backgroundSize: course.imageUrl ?'contain':'contain', // Cover ensures the image fills the card
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center', // Center the image
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay for better text contrast
                      padding: '16px',
                      borderRadius: '10px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
                        marginBottom: '8px',
                      }}
                    >
                      {truncateText(language === 'EN' ? course.courseNameEN : course.courseNameAR)}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'white',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
                      }}
                    >
                      {truncateText(language === 'EN' ? course.descriptionEN : course.descriptionAR)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Show 'Load More' button only if there are more courses to load */}
          {visibleCourses < courses.length && (
            <div className="load-more-container">
              <Button variant="contained" color="primary" style={{backgroundColor:"#721d20"}} onClick={loadMoreCourses}>
                {language==="EN"?"Show More":"عرض المزيد"}
              </Button>
            </div>
          )}
        </>
      )}

      {/* Modal component for course details */}
      <Modal isOpen={isModalOpen} onClose={closeModal} course={selectedCourse} language={language} />
    </div>
  );
};

export default Courses;
