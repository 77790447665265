import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { getStorage, ref, deleteObject } from 'firebase/storage'; // For Storage deletion
import { db } from '../../config/firebase';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Oval } from 'react-loader-spinner';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'; // Import TextField for search input
import './ViewPlaces.css';

const ViewCategory = () => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]); // State for filtered categories
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch all categories from Firestore
    const fetchCategories = async () => {
      try {
        const categoryCollection = collection(db, 'categories');
        const categorySnapshot = await getDocs(categoryCollection);
        const fetchedCategories = categorySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        setCategories(fetchedCategories);
        setFilteredCategories(fetchedCategories); // Initialize filteredCategories with all categories
      } catch (error) {
        console.error('Error fetching categories: ', error);
        setAlertMessage('Error fetching categories.');
        setAlertSeverity('error');
        setAlertOpen(true);
      }
      setLoading(false);
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    // Filter categories based on the search term
    const filtered = categories.filter((category) =>
      category.nameEN.toLowerCase().includes(searchTerm.toLowerCase()) ||
      category.nameAR.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCategories(filtered);
  }, [searchTerm, categories]);

  const handleConfirmDelete = async () => {
    setOpenConfirm(false);
    setLoading(true);
    const storage = getStorage();
    try {
      const categoryDoc = doc(db, 'categories', categoryToDelete.id);
      await deleteDoc(categoryDoc);
      const storageRef = ref(storage, categoryToDelete.icon);
      await deleteObject(storageRef);
      setCategories(categories.filter(category => category.id !== categoryToDelete.id));
      setFilteredCategories(filteredCategories.filter(category => category.id !== categoryToDelete.id));
      setAlertMessage('Category deleted successfully!');
      setAlertSeverity('success');
      setAlertOpen(true);
    } catch (error) {
      console.error('Error deleting category: ', error);
      setAlertMessage('Error deleting category.');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
    setLoading(false);
  };

  const handleDeleteClick = (category) => {
    setCategoryToDelete(category);
    setOpenConfirm(true);
  };

  const handleEdit = (category) => {
    navigate('/admin/add-category', { state: { category, isEdit: true } });
  };

  const handleCloseSnackbar = () => {
    setAlertOpen(false);
  };

  return (
    <div className="view-places-container">
      <h2>View Categories</h2>

      {/* Search bar for filtering categories */}
      <TextField
        label="Search Categories"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search by category name (EN/AR)"
      />

      {loading ? (
        <div className="loading-spinner">
          <Oval height={50} width={50} color="#4fa94d" />
        </div>
      ) : (
        <div>
          <table className="places-table">
            <thead>
              <tr>
                <th>Icon</th>
                <th>Category Name English</th>
                <th>Category Name Arabic</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredCategories.map((category) => (
                <tr key={category.id}>
                  <td>
                    <img src={category.icon} alt={category.nameEN} style={{ width: '50px', height: '50px' }} />
                  </td>
                  <td>{category.nameEN}</td>
                  <td>{category.nameAR}</td>
                  <td>
                    <button onClick={() => handleEdit(category)} disabled={deleting}>
                      {deleting ? <Oval height={16} width={16} color="#fff" /> : 'Edit'}
                    </button>
                    <button onClick={() => handleDeleteClick(category)} disabled={deleting}>
                      {deleting ? <Oval height={16} width={16} color="#fff" /> : 'Delete'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Confirm Delete Dialog */}
          <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
            <DialogTitle>Delete Category</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete "{categoryToDelete?.nameEN}"? This action cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenConfirm(false)}>Cancel</Button>
              <Button onClick={handleConfirmDelete} color="error">
                Yes, Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ViewCategory;
