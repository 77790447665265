// src/App.js
import React, { useState } from 'react'; // Ensure useState is imported
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminRoutes from './routes/AdminRoutes';
import AdminLogin from './admin/components/AdminLogin';
import './App.css'; // Import the CSS file
import ProtectedRoute from './admin/components/ProtectedRoute';
import HomePage from './user/HomePage';
import './i18n'; // Ensure your i18n configuration is imported

function App() {
  // const [language, setLanguage] = useState('en'); // Default language state
// 
  // const toggleLanguage = () => {
  //   setLanguage((prevLang) => (prevLang === 'en' ? 'ar' : 'en')); // Toggle between languages
  // };

  return (
    <Router>
      <div>
        <Routes>
          {/* Admin Routes */}
          <Route path="/" element={<HomePage/>} /> 
          <Route path="/admin/*" element={<ProtectedRoute><AdminRoutes /></ProtectedRoute>} />
          <Route path="/admin/login" element={<AdminLogin />} />
        </Routes>
      </div>
    </Router>
  );
}
// language={language} setLanguage={toggleLanguage} 
export default App;
