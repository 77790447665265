import React, { useState, useEffect } from 'react';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner'; // Import the loader
import Snackbar from '@mui/material/Snackbar'; // Material UI Snackbar
import MuiAlert from '@mui/material/Alert'; // Material UI Alert
import './AddPlace.css';

const AddPlace = () => {
  const [nameEN, setNameEN] = useState(''); // English name
  const [nameAR, setNameAR] = useState(''); // Arabic name
  const [loading, setLoading] = useState(false); // Loading state
  const [alertOpen, setAlertOpen] = useState(false); // Snackbar open state
  const [alertMessage, setAlertMessage] = useState(''); // Snackbar message
  const [alertSeverity, setAlertSeverity] = useState('success'); // Snackbar severity (success, error, etc.)
  const navigate = useNavigate();
  const location = useLocation();
  const placeToEdit = location.state?.place || null; // Get place data if available

  useEffect(() => {
    if (placeToEdit) {
      setNameEN(placeToEdit.nameEN); // Populate the form with data for editing (English name)
      setNameAR(placeToEdit.nameAR); // Populate with Arabic name if editing
    }
  }, [placeToEdit]);

  // Function to handle Snackbar closing
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loading spinner on submit

    try {
      if (placeToEdit) {
        // Update existing place
        const placeRef = doc(db, 'places', placeToEdit.id);
        await updateDoc(placeRef, { nameEN: nameEN, nameAR: nameAR });
        setAlertSeverity('success'); // Success state for the alert
        setAlertMessage('Place updated successfully');
      } else {
        // Add new place
        await addDoc(collection(db, 'places'), { nameEN: nameEN, nameAR: nameAR });
        setAlertSeverity('success');
        setAlertMessage('Place added successfully');
      }

      setLoading(false); // Hide loading spinner
      setAlertOpen(true); // Show success alert
      setTimeout(() => navigate('/admin/view-place'), 1500); // Redirect after 1.5 seconds
    } catch (error) {
      console.error('Error saving place:', error);
      setLoading(false); // Hide loading spinner
      setAlertSeverity('error'); // Error state for the alert
      setAlertMessage('An error occurred while saving the place');
      setAlertOpen(true); // Show error alert
    }
  };

  return (
    <div className="add-place-container">
      <h2>{placeToEdit ? 'Edit Place' : 'Add New Place'}</h2>
      <form onSubmit={handleSubmit} className="add-place-form">
        {/* English Place Name Input */}
        <div className="form-group">
          <label>Place Name (English):</label>
          <input
            type="text"
            value={nameEN}
            onChange={(e) => setNameEN(e.target.value)}
            required
          />
        </div>

        {/* Arabic Place Name Input */}
        <div className="form-group">
          <label>اسم المكان (Arabic):</label>
          <input
            type="text"
            value={nameAR}
            onChange={(e) => setNameAR(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="submit-button" disabled={loading}>
          {placeToEdit ? 'Update Place' : 'Add Place'}
        </button>
      </form>

      {/* Loading spinner */}
      {loading && (
        <div className="loading-spinner">
          <Oval
            height={80}
            width={80}
            color="#4fa94d"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      {/* Snackbar for alert messages */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Alert appears from the top-right
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity={alertSeverity} // Success, error, warning, info
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default AddPlace;
