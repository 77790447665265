// src/routes/AdminRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../admin/pages/Home';        // Import the Home component
import AddCourse from '../admin/components/AddCourse';
import ViewCourses from '../admin/components/ViewCourses';
import AddPlace from '../admin/components/AddPlace'; 
import AddCategory from '../admin/components/AddCategory';
import ViewPlaces from '../admin/components/ViewPlaces';
import ViewCategory from '../admin/components/ViewCategory';
import AddGallery from '../admin/components/AddGallery';
import ViewGallery from '../admin/components/ViewGallery';
import AddScheduledCourse from '../admin/components/AddScheduledCourse';
import ViewAllScheduledCourse from '../admin/components/ViewAllScheduledCourse';
import AddBanner from '../admin/components/AddBanner';
import ViewAllBanners from '../admin/components/ViewAllBanners';
const AdminRoutes = () => {
  return (
    <Routes>
      {/* The Home component serves as a wrapper with the Sidebar */}
      <Route path="/" element={<Home />}>
        {/* Nested routes will render inside Home via Outlet */}
        <Route path="add-course" element={<AddCourse />} />
        <Route path="view-courses" element={<ViewCourses />} />
        <Route path="add-place" element={<AddPlace />} />
        <Route path="view-place" element={<ViewPlaces />} />
        <Route path="add-category" element={<AddCategory />} />
        <Route path="view-category" element={<ViewCategory />} />
        <Route path="add-gallery" element={<AddGallery />} />
        <Route path="view-gallery" element={<ViewGallery />} />
        <Route path="add-scheduled" element={<AddScheduledCourse />} />
        <Route path="edit-scheduled-course/:courseId" element={<AddScheduledCourse />} />
        <Route path="view-scheduled" element={<ViewAllScheduledCourse />} />
        <Route path="add-banner" element={<AddBanner />} />
        <Route path="view-banners" element={<ViewAllBanners />} />

        
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
