import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Define the translations
const resources = {
  en: {
    translation: {
      welcome: "Welcome to Sajaya",
      search: "Search...",
      requestCourse: "Request a Course",
      languageToggle: "عربي",
      // Add more translations as needed
    },
  },
  ar: {
    translation: {
      welcome: "اهلا بك في سجايا",
      search: "ابحث...",
      requestCourse: "طلب دورة",
      languageToggle: "English",
      // Add more translations as needed
    },
  },
};

// Initialize i18next
i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // Default language
    fallbackLng: "en", // Use English if translation is not available in the current language
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
  });

export default i18n;
