import React from 'react';
import Sidebar from '../components/Sidebar';
import { Outlet } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../../App.css';

const Home = () => {
  return (
    <div className="admin-container">
      <Sidebar />
      <div className="content">
        <TransitionGroup>
          <CSSTransition
            timeout={300}
            classNames="content"
          >
            <Outlet />
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
};

export default Home;
