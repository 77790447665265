// src/admin/components/AddScheduledCourse.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Oval } from 'react-loader-spinner';
import './AddScheduledCourse.css';

const AddScheduledCourse = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [places, setPlaces] = useState([]); // Store available places from Firestore
  const [selectedPlaces, setSelectedPlaces] = useState([]); // Store selected places for the course
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  
  const { courseId } = useParams(); // Get the courseId from the URL
  const navigate = useNavigate();
  
  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const coursesSnapshot = await getDocs(collection(db, 'courses'));
        const coursesData = coursesSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(course => !course.ScheduledCourse); // Filter out scheduled courses

        setCourses(coursesData);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    const fetchPlaces = async () => {
      try {
        const placesSnapshot = await getDocs(collection(db, 'places')); // Assuming the collection is named 'places'
        const placesData = placesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setPlaces(placesData); // Set available places
      } catch (error) {
        console.error('Error fetching places:', error);
      }
    };

    fetchCourses();
    fetchPlaces(); // Fetch available places from Firestore
  }, []);

  useEffect(() => {
    // If courseId is present in the URL, fetch that course's data for editing
    if (courseId) {
      const fetchCourseForEdit = async () => {
        try {
          const courseDoc = await getDoc(doc(db, 'courses', courseId));
          if (courseDoc.exists()) {
            const courseData = courseDoc.data();
            setSelectedCourse(courseDoc.id);
            setStartDate(courseData.startDate || '');
            setEndDate(courseData.endDate || '');
            setSelectedPlaces(courseData.places || []); // Prepopulate selected places if available
          }
        } catch (error) {
          console.error('Error fetching course for editing:', error);
        }
      };

      fetchCourseForEdit();
    }
  }, [courseId]);

  // Function to handle selecting multiple places from the dropdown
  const handlePlaceChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => {
      const selectedPlace = places.find(place => place.nameEN === option.value);
      return { nameEN: selectedPlace.nameEN, nameAR: selectedPlace.nameAR };
    });

    setSelectedPlaces(selectedOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCourse || !startDate || !endDate || selectedPlaces.length === 0) {
      setAlertMessage('Please fill out all fields, including selecting at least one place.');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    setLoading(true); // Show loading spinner

    try {
      const courseDoc = doc(db, 'courses', selectedCourse);
      await updateDoc(courseDoc, {
        startDate,
        endDate,
        places: selectedPlaces, // Save selected places as objects with nameEN and nameAR
        ScheduledCourse: true // Mark course as scheduled
      });

      setAlertMessage('Course scheduled successfully!');
      setAlertSeverity('success');
      setAlertOpen(true);

      setTimeout(() => navigate('/admin/view-scheduled'), 1500); // Navigate back to view courses after success
    } catch (error) {
      console.error('Error scheduling course:', error);
      setAlertMessage('Failed to schedule course');
      setAlertSeverity('error');
      setAlertOpen(true);
    }

    setLoading(false); // Hide loading spinner
  };

  const handleCloseSnackbar = () => {
    setAlertOpen(false);
  };

  return (
    <div className="add-scheduled-course-container">
      <h2>{courseId ? 'Edit Scheduled Course' : 'Schedule a Course'}</h2>
      <form onSubmit={handleSubmit} className="add-scheduled-course-form">
        {!courseId && (
          <div className="form-group">
            <label>Select a Course:</label>
            <select
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
              required
            >
              <option value="">Select a course</option>
              {courses.map((course) => (
                <option key={course.id} value={course.id}>
                  {course.courseNameEN} / {course.courseNameAR}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="form-group">
          <label>Start Date:</label>
          <input
            type="date"
            value={startDate}
            min={today} // Prevent selecting past dates for start date
            onChange={(e) => setStartDate(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>End Date:</label>
          <input
            type="date"
            value={endDate}
            min={startDate || today} // End date starts from selected start date
            onChange={(e) => setEndDate(e.target.value)}
            required
          />
        </div>
        {/* Dropdown for selecting multiple places */}
        <div className="form-group">
          <label>Select Places:</label>
          <select
            multiple
            value={selectedPlaces.map(place => place.nameEN)} // Map selected places to their English names
            onChange={handlePlaceChange}
            className="places-dropdown"
            required
          >
            {places.map((place) => (
              <option key={place.id} value={place.nameEN}>
                {place.nameEN} - {place.nameAR}
              </option>
            ))}
          </select>
          <p className="help-text">Hold down the Ctrl (Windows) or Command (Mac) button to select multiple places.</p>
        </div>
        <div className="form-group">
          <button type="submit" disabled={loading}>
            {loading ? (
              <Oval
                height={20}
                width={20}
                color="#fff"
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              'Done'
            )}
          </button>
        </div>
      </form>

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity={alertSeverity}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default AddScheduledCourse;
