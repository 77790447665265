import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import './Banner.css';
import Modal from '../Modal/Modal';
import noImage from '../../../assets/no_img.png'
const Banner = ({ language }) => {
  const [bannerCourses, setBannerCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchBannerCourses = async () => {
      try {
        const q = query(collection(db, 'courses'), where('banner', '==', true));
        const querySnapshot = await getDocs(q);
        const courses = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setBannerCourses(courses);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching banner courses:', error);
        setLoading(false);
      }
    };

    fetchBannerCourses();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % bannerCourses.length);
    }, 5000); // Automatically go to the next slide every 5 seconds
    return () => clearInterval(interval);
  }, [bannerCourses]);

  const openModal = (course) => {
    setSelectedCourse(course);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedCourse(null);
    setIsModalOpen(false);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerCourses.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? bannerCourses.length - 1 : prevIndex - 1
    );
  };

  // Helper function to truncate description to 10 words
  const truncateDescription = (description) => {
    const words = description.split(' ');
    return words.length > 12 ? words.slice(0, 12).join(' ') + '...' : description;
  };

  if (loading) {
    return <div>{language === "EN" ? "Loading banners..." : "جاري تحميل ... "}</div>;
  }

  return (
    <div className="banner-container">
      {bannerCourses.length === 0 ? (
        <></>
        // <p>{language === 'EN' ? 'No banner courses available' : 'لا توجد دورات بنر متاحة'}</p>
      ) : (
        <>
          <div className="banner-cards">
            {bannerCourses.map((course, index) => (
              <div
                key={course.id}
                className={`banner-card ${currentIndex === index ? 'active' : ''}`}
                style={{ 
                  display: currentIndex === index ? 'flex' : 'none',
                  flexDirection: language === 'AR' ? 'row-reverse' : 'row' // Switch layout based on language
                }}
                onClick={() => openModal(course)}
              >
                {/* Left 25% for text and button */}
                <div className="banner-content">
                  <h2>{language === 'AR' ? course.courseNameAR : course.courseNameEN}</h2>
                  <p>
                    {language === 'AR' 
                      ? truncateDescription(course.descriptionAR) 
                      : truncateDescription(course.descriptionEN)}
                  </p>
                  <button
                    className="open-modal-button"
                    onClick={() => openModal(course)}
                  >
                    {language === "EN" ? "Learn More" : "اعرف المزيد"}
                  </button>
                </div>

                {/* Right 75% for image */}
                <div
                  className="banner-image"
                  style={{ 
                    // backgroundImage: `url(${course.imageUrl?course.imageUrl:{noImage}})` 
                    backgroundImage: `url(${course.imageUrl ? course.imageUrl : noImage})`,
                    backgroundSize: course.imageUrl ?'contain':'contain', // Cover ensures the image fills the card
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
              </div>
            ))}
          </div>
          {/* Add Previous and Next buttons */}
          <button className="prev-button" onClick={prevSlide}>&lt;</button>
          <button className="next-button" onClick={nextSlide}>&gt;</button>
        </>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        course={selectedCourse}
        language={language}
      />
    </div>
  );
};

export default Banner;
