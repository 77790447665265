import React, { useState } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, MenuItem, Select, InputLabel, FormControl, Button 
} from '@mui/material';
import Modal from '../Modal/Modal';  // Import the Modal component
import './UpcomingEvents.css';

const UpcomingEvents = ({ upcomingCourses, language }) => {
  const [nameFilter, setNameFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);  // State for modal
  const [selectedCourse, setSelectedCourse] = useState(null); // Selected course for the modal

  // Extract unique categories from the upcoming courses for the dropdown
  const categories = [...new Set(upcomingCourses.map(course => (
    course.category ? (language === 'EN' ? course.category.nameEN : course.category.nameAR) : null
  )))].filter(Boolean);

  // Function to filter courses based on filters
  const filteredCourses = upcomingCourses.filter(course => {
    const courseName = language === 'EN' ? course.courseNameEN : course.courseNameAR;
    const courseCategory = course.category ? (language === 'EN' ? course.category.nameEN : course.category.nameAR) : '';

    const matchesName = courseName.toLowerCase().includes(nameFilter.toLowerCase());
    const matchesCategory = categoryFilter ? courseCategory === categoryFilter : true;
    const matchesStartDate = startDateFilter ? new Date(course.startDate) >= new Date(startDateFilter) : true;
    const matchesEndDate = endDateFilter ? new Date(course.endDate) <= new Date(endDateFilter) : true;

    return matchesName && matchesCategory && matchesStartDate && matchesEndDate;
  });

  // Open the modal with selected course details
  const openModal = (course) => {
    setSelectedCourse(course);
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCourse(null);
  };

  const formatDate = (date) => {
   
    const formattedDate = new Date(date).toLocaleDateString(language === 'AR' ? 'ar-EG' : 'en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    
    // Convert English numbers to Arabic if the language is Arabic
    
    if (language === 'AR') {
      return formattedDate.replace(/\d/g, (d) => '٠١٢٣٤٥٦٧٨٩'[d]);
    }else{
      return formattedDate;
    }
    
  };
  
  return (
    <div className="scheduled-courses-section">
      {/* <h2>{language === 'EN' ? "Upcoming Courses" : "الدورات القادمة"}</h2> */}
      
      <div className="filter-container">
        <div className="filter-item">
          <FormControl fullWidth>
            <InputLabel>{language === 'EN' ? "Category" : "الفئة"}</InputLabel>
            <Select 
              value={categoryFilter} 
              onChange={(e) => setCategoryFilter(e.target.value)}
              style={{backgroundColor:'white'}}
            >
              <MenuItem value="">{language === 'EN' ? "Select Category" : "اختر الفئة"}</MenuItem>
              {categories.map((category, index) => (
                <MenuItem key={index} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        
        <div className="filter-item">
          <TextField
            label={language === 'EN' ? "Start Date" : "تاريخ البدء"}
            type="date"
            style={{backgroundColor:'white'}}
            value={startDateFilter}
            onChange={(e) => setStartDateFilter(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </div>
        
        <div className="filter-item">
          <TextField
            label={language === 'EN' ? "End Date" : "تاريخ الانتهاء"}
            type="date"
            style={{backgroundColor:'white'}}
            value={endDateFilter}
            onChange={(e) => setEndDateFilter(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </div>
        
        <div className="filter-item">
          <TextField
            label={language === 'EN' ? "Course Name" : "اسم الدورة"}
            type="text"
            style={{backgroundColor:'white'}}
            placeholder={language === 'EN' ? "Filter by course name..." : "تصفية باسم الدورة..."}
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            fullWidth
          />
        </div>
      </div>

      {filteredCourses.length === 0 ? (
        <p>{language === 'EN' ? "No upcoming courses available." : "لا توجد دورات قادمة متاحة."}</p>
      ) : (
        <TableContainer
        component={Paper}
        sx={{
          boxShadow: '0 3px 10px rgba(0,0,0,0.2)',
          direction: language === 'AR' ? 'rtl' : 'ltr', // Set direction based on language
        }}
      >
        <Table aria-label="upcoming courses table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: '#721d20',
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: language === 'AR' ? 'right' : 'left',  // Align header text
                }}
              >
                {language === 'EN' ? "Course Name" : "اسم الدورة"}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: '#721d20',
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: language === 'AR' ? 'right' : 'left',  // Align header text
                }}
              >
                {language === 'EN' ? "Place" : "مكان انعقاد الدورة"}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: '#721d20',
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: language === 'AR' ? 'right' : 'left',  // Align header text
                }}
              >
                {language === 'EN' ? "Starts On" : "تبدأ في"}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: '#721d20',
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: language === 'AR' ? 'right' : 'left',  // Align header text
                }}
              >
                {language === 'EN' ? "Ends On" : "تنتهي في"}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: '#721d20',
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: language === 'AR' ? 'right' : 'left',  // Align header text
                }}
              >
                {language === 'EN' ? "Action" : "الإجراء"}
              </TableCell>
            </TableRow>
          </TableHead>
      
          <TableBody>
            {filteredCourses.map((course, index) => (
              <TableRow
                key={course.id}
                sx={{
                  backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white',
                  '&:hover': { backgroundColor: '#f1f1f1' },
                  transition: 'background-color 0.3s ease',
                }}
              >
                <TableCell
                  sx={{
                    textAlign: language === 'AR' ? 'right' : 'left',  // Align body text
                  }}
                >
                  {language === 'EN' ? course.courseNameEN : course.courseNameAR}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: language === 'AR' ? 'right' : 'left',  // Align body text
                  }}
                >
                  {course.places && course.places.length > 0 ? (
                    course.places.map((place, index) => (
                      <div key={index}>
                        •{language === 'EN' ? place.nameEN : place.nameAR}
                      </div>
                    ))
                  ) : (
                    'No places selected'
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: language === 'AR' ? 'right' : 'left',  // Align body text
                  }}
                >
                  {formatDate(course.startDate)}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: language === 'AR' ? 'right' : 'left',  // Align body text
                    direction: language === 'AR' ? 'rtl' : 'ltr',
                  }}
                >
                 {formatDate(course.endDate)}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: language === 'AR' ? 'right' : 'left',  // Align body text
                    direction: language === 'AR' ? 'rtl' : 'ltr',
                  }}
                >
                  <Button variant="contained" style={{backgroundColor:"#b79269"}} onClick={() => openModal(course)}>
                    {language === 'EN' ? "Request the course" : "طلب الدورة"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      )}

      {/* Modal component for course details */}
      <Modal isOpen={isModalOpen} onClose={closeModal} course={selectedCourse} language={language} />
    </div>
  );
};

export default UpcomingEvents;
